import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaDiscord,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/metasphaltnft",
  },
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/company/",
  },
  
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/metasphalt/",
  },
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/STG8hPJR8g",
  },
  
  
];

export default data;
