import { FaRegComments } from "react-icons/fa";

import BlogItemStyleWrapper from "./BlogItem.style";

const BlogItem = ({ thumbnail, title, timeStamp, categories, excerpt }) => {
  return (
    <BlogItemStyleWrapper className="single_blog_item">
      <div className="blog_thumb">
        
          <img src={thumbnail} alt="metasphalt nft blog" />
        
      </div>
      <div className="blog_content">
        <div className="blog_meta">
          {categories?.map((category, i) => (
            <a key={i}  className="category_title">
              {i > 0 ? ", " : ""}
              {category.title}
            </a>
          ))}
         
          <span className="time_stamp">{timeStamp}</span>
        </div>

        <a className="blog_title">
          {" "}
          {title}
        </a>
        <p>{excerpt} </p>
      
      </div>
    </BlogItemStyleWrapper>
  );
};

export default BlogItem;
