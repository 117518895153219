import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'Gold Series Racer Owners will be rewarded with land NFTs that they can customize on Metarena, our Gaming ecosystem.Own a Metasphalt NFT by Metasphalt Reveal date to Win a Gold Racer.',
    aboutDescription2: 'You can compete in Metarena with Influencers who have Honorary Series NFTs.You can compete with 50 different influencers.',
    aboutDescription3: 'Compete With Special Series Racers! Get skins of your favorite characters.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Metasphalt Racer NFTs",
           // text: "Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure."
        },
        {
            icon: aboutCardIcon2,
            title: "Metarena",
            //text: 'Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original.'
        },
        {
            icon: aboutCardIcon3,
            title: "METH Coin",
            //text: 'Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure.'
        }
    ]
}

export default data;