import thumb1 from "../../images/blog/blog-img.png";
import thumb2 from "../../images/blog/blog-img2.png";
import thumb3 from "../../images/blog/blog-img3.png";
import thumb4 from "../../images/blog/blog-img4.png";
import thumb5 from "../../images/blog/blog-img5.png";
import thumb6 from "../../images/blog/blog-img6.png";
import thumb7 from "../../images/blog/blog-img7.png";
import thumb8 from "../../images/blog/blog-img8.png";
import thumb9 from "../../images/blog/blog-img9.png";

const data = {
  posts: [
    {
      thumbnail: thumb1,
      timeStamp: "30 JUNE, 2022",
      title: "AMA:Dogan Kabak &Metasphalt",
      excerpt:
        "AMA was held with Dogan Kabak and his followers on Twitter Spaces.",
        categories: [
          {
            title: "AMA",
            url: "#",
          },
        ],
      
    },
    {
      thumbnail: thumb2,thumb3,thumb4,
  
      title: "METASPHALT was at ONGAME ISTANBUL!",
      excerpt:
        " We were at ONGAME ISTANBUL with the important infulencers and brands of the game world, on June 19th.🏁",
      timeStamp: "19 JUNE, 2022",
      categories: [
        {
          title: "GAMING",
          url: "#",
        },
      ],
    },
    {
      thumbnail: thumb3,
  
      title: "AMA: BCC x Metasphalt",
      excerpt:
        "AMA was held with Bekir Cagri Celik aka BCC and his followers on Twitter Spaces.",
      timeStamp: "23 MAY, 2022",
      categories: [
        {
          title: "AMA",
          url: "#",
        },
        {
          title: "NFT",
          url: "#",
        },
      ],
    },
    {
      thumbnail: thumb4,
    
      title: "Metasphalt Launch Event!",
      excerpt:
        "A great launch party with famous brands and people was organized by Metasphalt.",
      timeStamp: "14 MAY, 2022",
      categories: [
        {
          title: "EVENT",
          url: "#",
        },
      ],
    },
    {
      thumbnail: thumb5,
    
      title: "Metasphalt Sneak Peek",
      excerpt:
      "Metasphalt sneak peek is  live, which is secretly inserted in Turkey’s first Metaverse music clip, Bye-Bye by Rapper Rapozof and Ceza.",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "EVENT",
          url: "#",
        },
      ],
    },
    {
      thumbnail: thumb6,
  
      title: "Sponsorships",
      excerpt:
        "CASTROL, TazeFikir and BeePR brands are oficially Metasphalt's sponsor brands",
      timeStamp: "24 FEB, 2022",
      categories: [
        {
          title: "BRANDS",
          url: "#",
        },
      ],
    },
    
    
  ],
};

export default data;
