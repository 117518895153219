import styled from "styled-components";

const TeamStyleWrapper = styled.section`

  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;

  .v1_team_overlay {
    padding: 113px 0 140px 0;
    position: relative;
    width: 100%;
    height: 100%;
    background: #040c12;
    z-index: 1;
  }

  .metasphalt_team_content {
    margin-top: 53px;
    position: relative;
    z-index: 1;
    .row {
      row-gap: 30px;
    }
  }

  @media only screen and (max-width: 667px) {
    .v1_team_overlay {
      padding: 30px 0 100px 0;
    }
  }
.team_bear_img {
  position: absolute;
  left: 5%;
  top: -150px;
}
`;

export default TeamStyleWrapper;
