import SectionTitle from "../../../../common/sectionTitle";

import AboutInfoCardList from "../aboutInfoCardList";

import data from "../../../../assets/data/about/aboutv1";
import aboutThumb1 from "../../../../assets/images/nft/a.png";
import aboutThumb2 from "../../../../assets/images/nft/about_us_img2.png";
import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2 } = data;
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="$METH Token, Our Digital Currency" subtitle="$METH Token" />
              <div className="v1_about_us_right_text">
                <p>Metasphalt dApp was developed over the ERC-20 based Ethereum network. The ultimate goal of the project is to become a one-stop shop for commercializing both off-chain and on-chain content.</p>
              
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="v1_about_us_left_sect sm-mt-60">
          
                
                  <img src={aboutThumb1} alt="img" />
           
              
       
        </div>
      
      </div>
      </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
