const data = [
  {
    title: "What Is The Metasphalt Project?",
    text: "Metasphalt is a collection of 6666 randomly generated NFTs on the Ethereum Blockchain.",
  },
  {
    title: "Where Can I Buy Metasphalt NFTs?",
    text: "You can mint our NFTs on our website on the announced dates, and you can buy them on the marketplace Opensea.io.",
  },
  {
    title: "Where Can I Find More Information About Metasphalt?",
    text: "Our Discord server is the right place for real-time updates.The latest announcements and conversations will always be shared here and on our Twitter.",
  },
  {
    title: "Who Is Behind This Project?",
    text: "You can reach us on our Discord channel, you can also get information about us from the MEET THE RACER CREW section.",
  },
  {
    title: "How Much Are Metasphalt NFTs?",
    text: "Pre-sale at 0.08 ETH and general sale is TBA.",
  },
  {
    title: "Can I Get Free NFT?",
    text: "Definitely, follow us for giveaways.",
  },
];

export default data;
