import { FaLinkedinIn, FaTwitter, FaInstagram, FaYoutube} from "react-icons/fa";

import avatarThumb1 from "../../images/team/1-Belge.jpg";
import avatarThumb2 from "../../images/team/2-Ilay.jpg";
import avatarThumb3 from "../../images/team/3-Kaan.jpg";
import avatarThumb4 from "../../images/team/4-Rustu.jpg";
import avatarThumb5 from "../../images/team/5-MS.jpg";
import avatarThumb6 from "../../images/team/6-Musa.jpg";
import avatarThumb7 from "../../images/team/7-Ayse.jpg";
import avatarThumb8 from "../../images/team/8-Dilara.jpg";
import avatarThumb9 from "../../images/team/9-Ahmet.jpg";
import avatarThumb10 from "../../images/team/10-Gizem.jpg";
import avatarThumb11 from "../../images/team/11-Onur.jpg";
import avatarThumb12 from "../../images/team/12-Emre.jpg";
import avatarThumb13 from "../../images/team/13-Caglar.jpg";
const data = [
  {
    avatar: avatarThumb1,
    name: "Fatih Belge",
    designation: "Founder / Project Manager - MsC Data Driven Designer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/fatih-nureddin-belge/",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.linkedin.com/in/fatih-nureddin-belge/",
      },
    
    ],
  },
  {
    avatar: avatarThumb2,
    name: "İlay Alpgiray",
    designation: "Co-Founder / Director & Advertisement",
    socialLinks: [
      {
        icon: <FaYoutube />,
        url: "https://www.youtube.com/c/%C4%B0layAlpgiray",
      },

      {
        icon: <FaTwitter />,
        url: "https://twitter.com/ilayalpgiray",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/ilayalpgiray/",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Kaan Özmen",
    designation: "Co-Founder / Business Developer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/kaan-%C3%B6zmen-0804aa13b/",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/kaanozmen_/",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "Rüştü Kağan Yıldız",
    designation: "Co-Founder / Art Collector",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/r%C3%BC%C5%9Ft%C3%BC-ka%C4%9Fan-y%C4%B1ld%C4%B1z-b473401ab/",
      },
    
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/rustukagan/r",
      },
    ],
  },
  {
    avatar: avatarThumb5,
    name: "M.S. Belge",
    designation: "CEO / NFT Investor",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/selahaddin-belge-520180185/",
      },
      
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/msblge/",
      },
    ],
  },
  {
    avatar: avatarThumb6,
    name: "M. Deniz",
    designation: "Co-Founder / Financial Advisor",
    socialLinks: [
    
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/wandererrsoul/",
      },
    ],
  },
  {
    avatar: avatarThumb7,
    name: "Ayşe Sancılı(ayses.eth)",
    designation: "Tech Lead / Blockchain Developer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://twitter.com/Ayse_sancili",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/Ayse_sancili",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.linkedin.com/in/aysesancili/",
      },
    ],
  },
  {
    avatar: avatarThumb8,
    name: "Dilara Saatçi",
    designation: "Lead Designer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/dilarasaat%C3%A7%C4%B1/",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/dilarasaatci/",
      },
    ],
  },
  {
    avatar: avatarThumb9,
    name: "M.A. Ozkok",
    designation: "VFX Artist",
    socialLinks: [
      
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/animelih/",
      },
    ],
  },
  {
    avatar: avatarThumb13,
    name: "Caglar Gözüacik",
    designation: "Global Marketing Manager",
    socialLinks: [
      
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/%C3%A7a%C4%9Flar-g%C3%B6z%C3%BCa%C3%A7%C4%B1k-0712bb12",
      },
      {
        icon: <FaInstagram />,
        url: "https://instagram.com/caglargozuacik?igshid=YmMyMTA2M2Y=",
      },
    ],
  },
  {
    avatar: avatarThumb10,
    name: "Gizem Yavuz",
    designation: "Marketing & PR Strategist",
    socialLinks: [
      
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/_gizemyavuz/",
      },
    ],
  },
  
  {
    avatar: avatarThumb11,
    name: "Onur Gokce",
    designation: "Community Lead",
    socialLinks: [
      
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/aonurgokce/",
      },
    ],
  },
  {
    avatar: avatarThumb12,
    name: "E. Ray Linener",
    designation: "Community Lead",
    socialLinks: [
      
      {
        icon: <FaInstagram />,
        url: "Community Operations Manager",
      },
    ],
  },


];

export default data;
