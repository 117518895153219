import SectionTitle from "../../../../common/sectionTitle";
import data from "../../../../assets/data/about/dataV2";
import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2, artists } = data;
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="The Coolest Race Track In The Metaverse Universe: METARENA" subtitle="Metarena " />
              <div className="v1_about_us_right_text">
                <p>Get ready to compete in real time with meta racers in the Metarena."</p>
                <p>{aboutDescription2}</p>
              </div>
             
            </div>
          </div>
           <div className="col-md">
          
            {artists?.map((item, i) => (
            <div key={i}>
          <video width="100%" height="auto" loop  autoPlay muted
                      src={item.thumb}/>
                      </div>
                      ))}

          </div>
          </div>
          
            </div>
          
          
   


          
          
            
            
    
    
          
            

        
    </AboutStyleWrapper>
  );
};

export default About;
