import FooterBottom from "./footerBottom/FooterBottom";

import data from "../../../../assets/data/footer/footerDataV3";
import footerLogo from "../../../../assets/images/logo.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";

import FooterStyleWrapper from "./Footer.style";
const Footer = () => {
  return (
    <FooterStyleWrapper>
      
      {/* footer bottom  */}
      <FooterBottom />
    </FooterStyleWrapper>
  );
};

export default Footer;
