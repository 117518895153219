import character1 from "../../images/nft/slider-img.png";
import character2 from "../../images/nft/slider-img2.png";
import character3 from "../../images/nft/slider-img3.png";
import character4 from "../../images/nft/slider-img4.png";
import character5 from "../../images/nft/slider-img5.png";
import character6 from "../../images/nft/slider-img6.png";
import character7 from "../../images/nft/slider-img7.png";
import character8 from "../../images/nft/slider-img8.png";
import character9 from "../../images/nft/slider-img9.png";
import character10 from "../../images/nft/slider-img10.png";
import character11 from "../../images/nft/slider-img11.png";
import character12 from "../../images/nft/slider-img12.png";



const data = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character7,
  character8,
  character9,
  character10,
  character11,
  character12,

];

export default data;
