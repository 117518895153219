import SectionTitle from "../../../../common/sectionTitle";
import Counter from "../../../../common/counter";
import video from "../../../../assets/images/bg/METARENA.mp4";
import aboutImageLeft from "../../../../assets/images/nft/about_image_left.png";
import aboutImageRight from "../../../../assets/images/nft/about_image_right.png";
import AboutInfoCardList from "../aboutInfoCardList";
import AboutStyleWrapper from "./About.style";

const About = () => {
  return (
    <AboutStyleWrapper className="v2_about_us_section" id="about">
      <div className="v2_about_overlay"></div>
      <div className="container">
        <SectionTitle
          className="text-center"
          isCenter={true}
          title=" Metasphalt Is A Race Based Metaverse Project. It Is A Virtual World Where Users Have Racer Avatars."
          subtitle="What Is Metasphalt?"
        />
        <div className="v2_about_us_content">
          <div className="v2_about_us_text">
          <video width="100%" height="auto" loop  autoPlay muted
                      src={video}/>
         <p>
            Metasphalt Project  Aims To Build A Metarena In The Metaverse.
            </p>
            
          
            <p>
          
            </p>

            <div className="counter-wrap">
              <h4>
                Total NFTS <Counter end="6666" />{" "}
              </h4>
              <h4>
                Genesis NFTs <Counter end="1666" />
              </h4>
              <h4>
                Special NFTs <Counter end="50" />
              </h4>
             
            </div>
            
            
          </div>
      
          
      
         
        
      
          
        </div>

        {/* about card */}
       
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
