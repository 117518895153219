import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";

import CountdownTimer from "react-component-countdown-timer";

import Button from "../../../../common/button";
import Particle from "../../../../common/particle/v2";
import vid from "../../../../assets/images/banner/item1.mp4";


import BannerStyleWrapper from "./Banner.style";
const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [count, setCount] = useState(1);

  const settings = {
    count: 259200,
    showTitle: true, 
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "DAYS",
    hourTitle: "HOURS",
    minuteTitle: "MINUTES",
    secondTitle: "SECONDS", 
  };

  return (
    
    <BannerStyleWrapper className="metasphalt_v3_baner_sect" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-image-area3">
              {/* particles component */}
            
              <video width="50%" height="auto" loop  autoPlay  controls
                      src={vid}/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-conent3">
    
            <a href="https://mintparty.xyz/metasphalt/whitelist-1658740944615">
              <Button lg variant="blue" >
                  Whitelist now
                </Button>
                </a>
                
               
              <h1 className="banner-title text-uppercase">Mint soon</h1>
             
              <div className="banner-count-inner d-flex align-items-center">
                <div className="banner-btn-area">
                  <span
                    className="input-number-decrement"
                    onClick={() => (count > 1 ? setCount(count - 1) : count)}
                  >
                    –
                  </span>
                  <input
                    className="input-number"
                    type="text"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                  />
                  <span
                    className="input-number-increment"
                    onClick={() => setCount(count + 1)}
                  >
                    +
                  </span>
                </div>
                {/* <div className="metasphalt_v3_baner_buttons">MINT IS OFFLINE!
                {
                  account ? 
                  <Button lg variant="mint" onClick={() => mintModalHandle()}>
                    {" "}
                    Mint now
                  </Button> :
                  <Button lg variant="mint" onClick={() => connectWalletModalHanlde()}>
                    {" "}
                    Mint now
                  </Button>
                }
                </div> */}
               <div className="metasphalt_v3_baner_buttons">
              
                  <Button lg variant="mint"disabled>
                    {" "}
                    MINT IS NOT ACTIVE YET!
                  </Button> 
                
                
                </div>

              </div>
              <div className="banner-bottom-text text-uppercase">
               Mint Price 0.08 Eth + Gas
               Floor Price To the Moon
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
