import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 01 ",
        subtitle: "RELEASE!",
        text: "Releasing Project's Social Media and Website."
    }, 
    {
        icon: checkIcon,
        title: "Phase 02",
        subtitle: "NFTs!",
        text: "Metasphalt NFT Collection Release."
    }, 
    {
        icon: checkIcon,
        title: "Phase 03",
        subtitle: "PARTNERSHIPS!",
        text: "Tazefikir & BeePR Partnerships Announcement.",
        

    }, 
    {
        icon:  checkIcon,
        title: "Phase 04",
        subtitle: "METARENA!",
        text: "Metarena Announcement"
    }, 
    {
        icon: checkIcon,
        title: "Phase 05",
        subtitle: "INFULENCERS!", 
        text: " Dogan Kabak & Umit Erdim became our influencers"
    }, 
    {
        icon: checkIcon,
        title: "Phase 06",
        subtitle: "METASPHALT PARTY!", 
        text: "Many brands and famous people attended the Metasfalt launch party."
    }, 
    {
        icon: checkIcon,
        title: "Phase 07",
        subtitle: "WHITELIST!", 
        text: "Whitelist Announcement for Minting."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 08",
        subtitle: "MINT!", 
        text: "Sold Out Genesis Collection."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 09",
        subtitle: "METH TOKEN AND LISTINGS!", 

        text: <p><li>Meth Token Token Generation Event</li> 
        <li>CoinmarketCap an Coingecko listing </li>
        <li>DEX listing</li>
        <li>Audit</li>
        </p>
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 10",
        subtitle: "METASPHALT GAME!", 
        text: <p><li>Metasphalt Game demo Released on Testnet</li> 
        <li> Metarena Land Airdrop </li>
        <li> Rentable NFTs Released </li>
        <li> Parnetship with companies for MetarenAD</li>
        </p>
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 11",
        subtitle: "METASPHALT FULL VERSION", 
        text: "Launching MEtasphalt Full Version."
    }, 
]

export default data;