import SectionTitle from "../../../../common/sectionTitle";
import leftimg from "../../../../assets/images/bg/WL1.png";
import data from "../../../../assets/data/about/aboutv1";
import data2 from "../../../../assets/data/about/dataV2";


import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2, aboutDescription3} = data;
  const {  artists } = data2;
  return (
    <AboutStyleWrapper id="about">
      <SectionTitle
          className="text-center"
          isCenter={true}
          subtitle=" The Metasphalt Universe Consists Of Three Axes."
        
       
        />
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="Compete With Influencers & Special Series Racers." subtitle="Metasphalt Racer NFTs" />
              <div className="v1_about_us_right_text">
                <p>{aboutDescription1}</p>
                <p>{aboutDescription2}</p>
                <p>{aboutDescription3}</p>
              </div>
            </div>
          </div>
          
          <div className="col-sm">
          
   
          <div class="img">

                  <img src={leftimg} alt="img" />
                
                  </div>
              
              
             
                
                
           
              
       
     
          

                  </div>
        
          </div>
          </div>
          
         
      
    </AboutStyleWrapper>
  );
};

export default About;
