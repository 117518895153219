import aboutThumb11 from "../../images/nfts/METARENA1.mp4";
import aboutThumb22 from "../../images/nfts/METARENA.mp4";
//import aboutThumb2 from "../../images/nfts/gold.png";
//import aboutThumb3 from "../../images/nfts/honor.png";
//import aboutThumb4 from "../../images/nfts/special.png";
//import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Metasphalt Aims To Build A Metarena In The Metaverse.",
  aboutDescription2:
    '',
  artists: [
    {
      thumb: aboutThumb11,
      //icon: aboutIcon,
      name: "",
      //role: "Artist",
    },
    //{
//  thumb: aboutThumb22,
    //   //icon: aboutIcon,
//  name: "",
   //  }
    //   //role: "Co-Artist",
    // },
    // {
    //   thumb: aboutThumb3,
    //   //icon: aboutIcon,
    //   name: "Honorary Racers",
    //   //role: "Co-Artist",

    // },
    // {
    //   thumb: aboutThumb4,
    //   //icon: aboutIcon,
    //   name: "Special Racers ",
    //   //role: "Co-Artist",


      
  ]
  
};

export default data;
