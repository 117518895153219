const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Connect Your Wallet"
    },
    {
        num: "2",
        mintColor: "pink",
        text: "Select Your Quantity"
    },
    {
        num: "3",
        mintColor: "green",
        text: "Confirm the Transaction"
    },
    {
        num: "4",
        mintColor: "pink",
        text: "Receive Your  Metasphalt NFTs"
    },
]

export default data;