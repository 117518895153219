import clientThumb1 from "../../assets/images/partners/1.png";
import clientThumb2 from "../../assets/images/partners/2.png";
import clientThumb3 from "../../assets/images/partners/3.png";
import clientThumb4 from "../../assets/images/partners/4.png";


const data = [
 clientThumb1,
  clientThumb2,
  clientThumb3,
  clientThumb4,
  
];

export default data;
