import { FaTwitter, FaInstagram, FaYoutube} from "react-icons/fa";

import avatarThumb1 from "../../images/team/1-Dogan.jpg"; 
import avatarThumb2 from "../../images/team/2-Umit.jpg"; 
import avatarThumb3 from "../../images/team/3-Batuhan.jpg"; 


const data = [
  {
    avatar: avatarThumb1,
    name: "Doğan Kabak",
    designation: "Youtuber",
    socialLinks: [
      {
        icon: <FaYoutube />,
        url: "https://www.youtube.com/channel/UCrVNGRw7QCtmAWtg2t7hrjg",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/dogan_kabak",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/dogankabak/",
      },
    ],
  },    
  {
    avatar: avatarThumb2,
    name: "Ümit Erdim",
    designation: "Pilot&Youtuber",
    socialLinks: [
      {
        icon: <FaYoutube />,
        url: "https://www.youtube.com/channel/UC9c7W-y3EDWxw4GKZwHX1dg",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/umiterdim",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/umit.erdim/",
      },
    ],
  },    
  {
    avatar: avatarThumb3,
    name: "Batuhan Cakmak",
    designation: "Crypto Investor",
    socialLinks: [
      {
        icon: <FaYoutube />,
        url: "https://www.youtube.com/c/Batuhan%C3%87akmak",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/BatuhanCakmak",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/batuhancakmak/",
      },
    ],
  },    
  
];

export default data;
